export interface ITransactionProps {
  amount: string;
  currency_code: string;
  comments: string;
  recipient_email: string;
  otp_code?: string;
}

export const initTransaction = {
  amount: "",
  currency_code: "USD",
  comments: "",
  recipient_email: "",
  otp_code: "",
};

export interface ITransactionResponseProps {
  amount: number;
  currency_code: string;
  created_at: string;
  id: string;
  comments: string;
  recipient_email: string;
  recipient_id: number;
  recipient_first_name: string;
  recipient_last_name: string;
}

export interface ITransactionStatus {
  [x: string]: {
    name: string;
    textColor: string;
    backgroundColor: string;
  };
}
