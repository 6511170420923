import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { UTCLocalTimeStamp } from "components/shared/UTCLocalTimestampComponent";
import colors from "constants/colors";
import { IBankAccountProps } from "interfaces/bank-account";
import { CustomError } from "interfaces/error";
import {
  ITransactionResponseProps,
  ITransactionStatus,
} from "interfaces/transaction";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { showToast } from "redux/toast/action";
import { getAccountDetail, getTransactionDetail } from "utils/apiProvider";
import authProvider from "utils/authProvider";
import { renderMoney } from "utils/formatMoney";
import { t } from "utils/translate";

const TRANSACTION_STATUS: ITransactionStatus = {
  CLO: {
    name: "Completed",
    textColor: colors.Success500,
    backgroundColor: colors.Success300,
  },
  PEN: {
    name: "Pending",
    textColor: colors.Grey500,
    backgroundColor: colors.Grey300,
  },
  DEC: {
    name: "Declined",
    textColor: colors.Error500,
    backgroundColor: colors.Error300,
  },
  CAN: {
    name: "Canceled",
    textColor: "",
    backgroundColor: "",
  },
};

const TransactionDetail = () => {
  const params = useParams<{ reference: string; code: string }>();
  const [currency, setCurrency] = useState<IBankAccountProps>({
    currency: "",
    currency_name: "",
    balance: "",
  });
  const dispatch = useDispatch();

  const [data, setData] = useState<ITransactionResponseProps>({
    amount: 0,
    created_at: "",
    currency_code: "USD",
    id: "",
    comments: "",
    recipient_email: "",
    recipient_id: 0,
    recipient_first_name: "",
    recipient_last_name: "",
  });

  const fetchDetail = async () => {
    const userId = await authProvider.getUserID();
    if (params.reference && userId) {
      getTransactionDetail(params.reference, userId)
        .then((resp) => {
          setData(resp.data);
        })
        .catch((err: AxiosError<CustomError>) => {
          if (err.response?.status !== 401) {
            dispatch(
              showToast({
                type: "error",
                title:
                  err.response?.data.error.message ??
                  "Can not get transaction.",
              })
            );
          }
        });
    }
  };

  const fetchAccountDetail = async () => {
    const userId = await authProvider.getUserID();
    if (params?.code && userId) {
      getAccountDetail(params.code, userId)
        .then((resp: any) => {
          setCurrency(resp);
        })
        .catch((err: AxiosError<CustomError>) => {
          if (err.response?.status !== 401) {
            dispatch(
              showToast({
                type: "error",
                title:
                  err.response?.data.error.message ?? "Can not get account.",
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAccountDetail();
    fetchDetail();
  }, []);

  return (
    <Box>
      <Box>
        <Link to="/accounts" style={{ fontSize: "14px" }}>
          Account
        </Link>
        <ArrowForwardIosIcon
          sx={{ fontSize: 10, marginLeft: 1, marginRight: 1 }}
        />
        <Link
          to={`/accounts/${currency.currency}`}
          style={{ fontSize: "14px" }}
        >
          {currency.currency_name}
        </Link>
        <ArrowForwardIosIcon sx={{ fontSize: 10, marginLeft: 1 }} />
      </Box>
      <Box sx={{ margin: "10px 0 30px" }}>
        <Box fontSize="">
          {renderMoney(data.amount, currency.currency, "h1", 25)}
        </Box>
        <Box sx={{ display: "flex" }}>
          Transferred to
          <Typography sx={{ color: colors.Primary500, marginLeft: "5px" }}>
            {data.recipient_first_name} {data.recipient_last_name}
          </Typography>
        </Box>
        <span
          style={{
            fontSize: 12,
            padding: "4px 8px",
            borderRadius: 100,
            background: TRANSACTION_STATUS.CLO.backgroundColor,
            color: TRANSACTION_STATUS.CLO.textColor,
          }}
        >
          {TRANSACTION_STATUS.CLO.name}
        </span>
      </Box>

      <Card>
        <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
          <Stack gap="24px">
            <FormControl>
              <FormLabel>Date of transaction</FormLabel>
              <UTCLocalTimeStamp date={data?.created_at} />
            </FormControl>
            <FormControl>
              <FormLabel>Reference no.</FormLabel>
              {data.id}
            </FormControl>
            <FormControl>
              <FormLabel>{t("comments_for_recipient")}</FormLabel>
              {data.comments || "-"}
            </FormControl>
            <FormControl>
              <FormLabel sx={{ color: "black", fontSize: 18 }}>
                Suspicious transaction ?
              </FormLabel>
              <Box sx={{ display: "flex" }}>
                Contact us at{" "}
                <a
                  href={`mailto:support@seaboard.sg?subject=Support Request: TRANSACTION ${data.id}&body=Hi,`}
                  style={{ marginLeft: "3px" }}
                >
                  support@seaboard.sg
                </a>
              </Box>
            </FormControl>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TransactionDetail;
