import { IReduxUserState } from "interfaces/redux";
import { IRoute } from "interfaces/router";
import { AccountDetail, AccountList } from "pages/accounts";
import BulkTransfer from "pages/accounts/bulk-transfer/BulkTransfer";
import TransactionDetail from "pages/accounts/transaction-detail";
import Login from "pages/login";
import EmailConfirmation from "pages/login/email-confirmation";
import ResetPassword from "pages/login/reset-password";
import UserForm from "pages/users/form";
// import { UserForm } from 'pages/users';
import UserTransferForm from "pages/users/transfer";
import VerifiedScreen from "pages/users/verified";
import VerifiedFail from "pages/users/verified-fail";
import WalletHolderCardList from "pages/wallet-holder/WalletHolderCardList";
import WalletHolderDetail from "pages/wallet-holder/WalletHolderDetail";
import WalletHolderList from "pages/wallet-holder/WalletHolderList";
import AnonTemplate from "themes/AnonTemplate";

const anonRoutes: IRoute[] = [
  {
    path: "/login",
    pageComponent: Login,
    pageTitle: "Login",
  },
];

const userRoutes = [
  {
    path: "/",
    pageComponent: WalletHolderList,
    pageTitle: "Wallet Holder List",
  },
  {
    path: "/verified",
    pageComponent: VerifiedScreen,
  },
  {
    path: "/verified/:id/:email",
    pageComponent: VerifiedScreen,
  },
  {
    path: "/wallet-holder/show/:id",
    pageComponent: WalletHolderDetail,
    pageTitle: "Wallet Holder Details",
  },
  {
    path: "/wallet-holder/card/:id",
    pageComponent: WalletHolderCardList,
    pageTitle: "Wallet Holder Card List",
  },
  {
    path: "/wallet-holders/add",
    pageComponent: UserForm,
    pageTitle: "Add Wallet Holder",
  },
  {
    path: "/wallet-holders/edit/:id",
    pageComponent: UserForm,
    pageTitle: "Edit Wallet Holder",
  },
  {
    path: "/wallet-holders/transfer/:id",
    pageComponent: UserTransferForm,
    // pageTitle: 'Transfer'
  },
  {
    path: "/wallet-holders/verified-cancel",
    pageComponent: VerifiedFail,
  },
  {
    path: "/accounts",
    pageComponent: AccountList,
    pageTitle: "Account List",
  },
  {
    path: "/accounts/:id",
    pageComponent: AccountDetail,
    pageTitle: "Account Details",
  },
  {
    path: "/transaction/:code/:reference",
    pageComponent: TransactionDetail,
    pageTitle: "Transfer Details",
  },
  {
    path: "/bulk-transfer/import",
    pageComponent: BulkTransfer,
    pageTitle: "Bulk Transfer import",
  },
];

const getAvailableRoutes = (user: IReduxUserState | null): IRoute[] => {
  if (!user) {
    return [
      ...anonRoutes,
      {
        path: "/",
        pageComponent: Login,
        pageTitle: "Login",
      },
      {
        path: "/email-confirmation",
        pageComponent: EmailConfirmation,
        template: AnonTemplate,
        pageTitle: "Email Confirmation",
      },
      {
        path: "/reset-password",
        pageComponent: ResetPassword,
        template: AnonTemplate,
        pageTitle: "Reset Password",
      },

    ];
  }
  return [
    ...anonRoutes,
    ...userRoutes,
    {
      path: "/email-confirmation",
      pageComponent: EmailConfirmation,
      template: AnonTemplate,
    },
    {
      path: "/reset-password",
      pageComponent: ResetPassword,
      template: AnonTemplate,
    },
  ];
};

export default getAvailableRoutes;
